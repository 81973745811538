// 多语言对应的前缀链接
// LangUrlMap 修改时 DecodeLangUrlMap也需要同时修改
export interface LangUrlMap {
  zh: string
  en: string
}
export const langUrlMap: LangUrlMap = {
  zh: '/zh-CN/',
  en: '/en-US/'
}

// 根据路径反查语言

export interface DecodeLangUrlMap {
  '/zh-CN/': string
  '/en-US/': string
}
export const decodeLangUrlMap: DecodeLangUrlMap = {
  '/zh-CN/': 'zh',
  '/en-US/': 'en'
}

export function relpaceLangUrl(url: string, lang: keyof LangUrlMap) {
  // 检测语言是否有变化
  const langIndex = url.indexOf(langUrlMap[lang])
  // 展示的语言与路径语言不一样,替换路径
  if (langIndex === -1) {
    const newUrl = url.replace(/\/.*?\//, langUrlMap[lang])
    return newUrl
  } else {
    return url
  }
}
