
export default {
  translation: {
    title1: '游戏化互动（GI）',
    title2: '全球云服务商',
    docTitle: '开发者',
    login: '登录',
    home: {
      download: {
        title: '扫码体验',
        scanQrcode: '扫描二维码',
        downloadText: '下载体验'
      }
    },
    footer: {
      company: '忽然科技（上海）有限公司    业务咨询邮箱：help@sud.tech'
    }
  }
}
