import React from 'react'
import ReactDOM from 'react-dom'
import './index.less'
import './styles/reset.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <App />,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
