import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import resources from './resources'
import LanguageDetector from 'i18next-browser-languagedetector'
import { decodeLangUrlMap, DecodeLangUrlMap } from 'utils'
const url = window.location
const urlPathname = url.pathname
const res = urlPathname.match(/\/.*?\//)
let defautLang = ''
console.log(res, 'res')
if (res) {
  const keyMap = res[0] as keyof DecodeLangUrlMap
  defautLang = decodeLangUrlMap[keyMap]
  console.log(defautLang, 'defautLang')
}

const lang = defautLang || 'zh'
console.log(lang)

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'zh',
    lng: lang,
    debug: true,

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

export default i18n
