
export default {
  translation: {
    title: 'Sud Interactive Game Platform',
    docTitle: 'Developers',
    login: 'Log in',
    home: {
      download: {
        title: 'Code scanning experience',
        scanQrcode: 'Scan QR code',
        downloadText: 'Download'
      }
    },
    footer: {
      company: 'Sud Technology Co., Ltd. Email Address for Business Consultation: help@sud.tech'
    }
  }
}
