import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

interface RouteProp {
  path: string
  exact: boolean
  title: string,
  loader: any
}
const routes: RouteProp[] = [
  {
    path: '/',
    exact: true,
    title: 'home',
    loader: lazy(() => import('pages/Home'))
  },
  {
    path: '*',
    exact: true,
    title: 'home',
    loader: lazy(() => import('pages/Home'))
  }
]
const routers =
  <BrowserRouter>
    <Suspense fallback={<div></div>}>
      <Switch>
        {
          routes.map((route) => {
            return (
              <Route
                key={route.path}
                path={route.path}
                exact={route.exact}
                component={route.loader}
              />
            )
          })
        }
      </Switch>
    </Suspense>
  </BrowserRouter>

export default routers
